var Accordions = {};

Accordions.init = function() {
	this.bindings();
};

Accordions.bindings = function() {
    $('.accordion-heading').on('click', Accordions.AccToggle);
};

Accordions.AccToggle = function() {
    var accordion = $(this).parent('.accordion');

    accordion.toggleClass('active');
    accordion.find('.accordion-content').slideToggle(250);
};

$(document).ready(function(){
	Accordions.init();
});